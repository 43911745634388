$(document).ready(function()
{
  /* show alert if session expired */

  $('.bg-alert__session-expired').hide();
  if (window.location.hash === '#session_expired') {
    $('.bg-alert__session-expired').show();
  }
});

var h = h || {};

h.groupedSitesApp = new Backbone.Marionette.Application();

h.groupedSitesApp.WhitelistedSitesModel = Backbone.Model.extend({
  url: bg_consts.API.GET_WHITELISTED_SITES
});

h.groupedSitesApp.GroupedSitesModel = Backbone.Model.extend({
  url: bg_consts.API.GET_SITES_BY_GROUPS
});

h.groupedSitesApp.on('start', function (isSearchingForWhitelistedSites) {

  h.groupedSitesApp.addRegions({
    'mainRegion': '.modal_region'
  });

  // Create instance of the model
  var _userSitesModel = new h.groupedSitesApp.GroupedSitesModel();
  var params = {};

  if (isSearchingForWhitelistedSites === true) {
      _userSitesModel = new h.groupedSitesApp.WhitelistedSitesModel();
  } else {
      params = {
        groups: ["Comercio 2.0"]
      }
  }

  _userSitesModel.set("isSearchingForWhitelistedSites",isSearchingForWhitelistedSites);

  // Fetch data then create the view
  _userSitesModel.fetch({
    type: 'POST',
    data: params,
    success: function () {
      h.groupedSitesApp.mainRegion.show(
          new h.groupedSitesApp.MainView({model: _userSitesModel})
      );
    }
  });
});

// The view containing the list of whitelisted sites
h.groupedSitesApp.MainView = Marionette.ItemView.extend({
  'tagName': 'div',
  'template': '.tef-template-whitelisted-site-modal',
  ui: {
    'whitelistedModal': '#whitelisted-site-modal',
    'siteList': 'span.whitelisted_container'
  },
  onRender: function () {

    this.ui.whitelistedModal.modal('show');

    var sitesList = "<ul>";

    $.each(this.model.get("data"), function(id, item){
      sitesList += "<li>" + item + "</li>";
    });

    sitesList += "</ul>";

    this.ui.siteList.append(sitesList);
  }
});


